import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollManager = () => {
  const location = useLocation();

  useEffect(() => {
    // Restore saved scroll position if it exists; otherwise scroll to the top
    const savedScroll = window.history.state?.scroll || [0, 0];
    window.scrollTo(savedScroll[0], savedScroll[1]);

    // Save the current scroll position in the browser history on scroll
    const onScroll = () => {
      const scroll = [window.scrollX, window.scrollY];
      window.history.replaceState({ ...window.history.state, scroll }, "");
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [location]);

  return null; // This component does not render anything
};

export default ScrollManager;