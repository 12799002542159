import React, { useState, useContext } from 'react';
import MyUserContext from './../myUserContext.js';
import {localizeKey, localizeArt} from './../localize.js';

function Collapsible({ children, buttonText, collapsed, doOverrideNativeText }) {
  const myUser = useContext(MyUserContext);

  const [isVisible, setIsVisible] = useState(collapsed === false ? true : false);

  const toggleVisibility = () => {
    setIsVisible(prev => !prev);
  };

  let divClass = "flex items-center justify-center h-8 leading-none hover:cursor-pointer text-blue-400 border-gray-300 border-t-0";
  if (isVisible === true) {
    divClass += " border-b-0";
  }

  const language = myUser ? myUser.myUserSettings.lang : 'ru';

  return (
    <>
      <div class={divClass} onClick={toggleVisibility}>
        {isVisible ? ( doOverrideNativeText ? "" : localizeKey('collapsibleHideCap', language) ) + " " + buttonText : ( doOverrideNativeText ? "" : localizeKey('collapsibleShowCap', language) ) + " " + buttonText}
      </div>
      {isVisible && 
          <div>
            {children}
          </div>
      }
    </>
  );
}

export default Collapsible;