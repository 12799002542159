import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import {localizeKey, localizeArt} from './../localize.js';
import axiosInstance from './../axiosInstance.js';
import MyUserContext from './../myUserContext.js';

const Notifications = ( {unreadCountCallback} ) => {
  const myUser = useContext(MyUserContext);

  const [notifications, setNotifications] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);

  const fetchNotifications = () => {
    axiosInstance
    .get(`/notifications`)
    .then((response) => {      
      setNotifications(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
  };

  const markAsRead = (id) => {
    axiosInstance
    .post(`/notifications/${id}/markAsRead`)
    .then((response) => {
      const newUnreadCount = Math.max(0, unreadCount - 1);
      unreadCountCallback(newUnreadCount);
      setUnreadCount(newUnreadCount);
      fetchNotifications();
    })
    .catch((error) => {
      console.error('Failed to mark notification as read:', error);
    })
  };

  const fetchUnreadCount = () => {
    axiosInstance
    .get('/notifications/unreadCount')
    .then((response) => {
      setUnreadCount(response.data.unreadCount);
      unreadCountCallback(response.data.unreadCount);
    })
    .catch((error) => {
      console.error('Failed to fetch unread notification count:', error);
    })
  };

  const parseStringToBold = (text) => {
    const parts = text.split(/(\*[^*]+\*)/); // Split the string by *...* pattern

    return parts.map((part, index) => {
      if (part.startsWith('*') && part.endsWith('*')) {
        return <strong key={index}>{part.slice(1, -1)}</strong>;
      } else {
        return part;
      }
    });
  };

  const NotificationCard = ({notification}) => {
    const navigate = useNavigate();

    const ntfText = notification.title;
    const link = notification.linkToEvent;


    let twClass = notification.notification.read === false ? "opacity-100" : "opacity-20";
    twClass = "hover:cursor-pointer" + " " + twClass; 

    return (
      <div class={twClass} onClick={
        () => {
          markAsRead(notification.notification.id);
          navigate(link);
        }
      }>
        <div>
          <div class="flex">
            <img class="max-w-12" src={notification.artImageUrl} />
            <div class="ml-2 flex items-center">
              <div class="mr-1 mb-1">
                <img class="max-w-7 rounded-full" src={notification.initiatingUserAvatarUrl}/>
              </div>
              <div class="text-sm leading-none">
                {parseStringToBold("*" + notification.initiatingUserName + "* " + ntfText)}
              </div>
            </div>
          </div>
          <div class="-mb-2">
            {new Date(notification.createdTime).toLocaleDateString(language, { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' })}
          </div>
        </div>
      </div>
    )
  }

  if (notifications === null) {
    fetchNotifications();
    fetchUnreadCount();
    return (
      <div></div>
    )
  }

  const language = myUser ? myUser.myUserSettings.lang : 'ru';

  return (
    <div class="container mx-auto px-4 w-full lg:w-1/2">
      {
      (notifications && notifications.length == 0) &&
      <div class="flex mt-2 md:mt-4 justify-center items-center text-center">
        {
        (language === "ru") ?
        <>
          У вас пока нет уведомлений.<br/>
          Подписывайтесь на других пользователей, чтобы получать их.
        </>
        :
        <>
          No notifications yet.<br/>
          Subscribe to other users to receive notifications.
        </>
        }
      </div>
      }

      {
      (notifications && notifications.length > 0) &&
      <ul className="container mx-auto mt-5">
        {notifications.map((notification) => (
          <li key={notification.notification.id} style={{ marginBottom: '5px', padding: '10px', border: '1px solid #ccc', 'border-radius': '7px' }}>
              <NotificationCard notification={notification}/>
          </li>
        ))}
      </ul>
      }
    </div>
  );
};

export default Notifications;
