import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import {localizeKey, localizeArt} from './../localize.js';
import MyUserContext from './../myUserContext.js';

const ImageScroller = ({ items }) => {
  const myUser = useContext(MyUserContext);

  const language = myUser ? myUser.myUserSettings.lang : 'ru';

  return (
    <div
      className="flex overflow-x-auto whitespace-nowrap items-center py-2" // Tailwind CSS classes
      style={{ scrollbarWidth: "none" }} // Hide scrollbar (optional for some browsers)
    >
      {items.map((item, index) => (
          <div class="inline-block mx-2">
            <Link
              key={index}
              to={item.link}
              reloadDocument
            >
              <img
                src={item.image}
                alt={`Image ${index}`}
                className="h-full w-32 object-contain cursor-pointer"
              />
              <div class="w-32 flex justify-center text-center">
                {
                  item.category === 1 &&
                    <div class="w-32 truncate text-xs">🎥&ensp;{item.name}</div>
                }
                {
                  item.category === 2 &&
                    <div class="w-32 truncate text-xs">🎮&ensp;{item.name}</div>
                }
                {
                  item.category === 3 &&
                    <div class="w-32 truncate text-xs">📖&ensp;{item.name}</div>
                }
              </div>
            </Link>
            {
            item.platformsRating.kinopoisk &&
              <div class="flex justify-center items-center">

                <img
                  src="/imdb_small_icon.png"
                  alt="Source"
                  className="rounded-md max-w-4 max-h-4"
                />
                <span class="text-[0.6rem]">
                  &nbsp;{item.platformsRating.imdb.toFixed(1)}
                </span>
                &nbsp;
                <img
                  src="/kinopoisk_small_icon.png"
                  alt="Source"
                  className="rounded-md max-w-4 max-h-4"
                />
                <span class="text-[0.6rem]">
                  &nbsp;{item.platformsRating.kinopoisk.toFixed(1)}
                </span>

              </div>
            }
          </div>
      ))}
    </div>
  );
};

export default ImageScroller;
